<template>
  <BaseForm title="Agenturkosten duplizieren" @onConfirmClicked="onConfirmClicked">
    <form>
      <v-container>
        <v-row>
          <v-col :cols="field.columnWidth || 6" v-for="(field, i) in config" :key="i">
            <BaseField :field="field" v-model="data[field.techName]" />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="data.createdBy"
              label="Ersteller"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </form>
  </BaseForm>
</template>

<script>
import { API } from "aws-amplify";
import BaseForm from "@/components/baseCrud/BaseForm.vue";
import BaseField from "@/components/baseCrud/BaseField.vue";
import { mapGetters } from "vuex";
import configFile from "./config.json";
import basicConfigFile from "./basicConfig.json";
import * as mutations from "@/graphql/mutations";
import * as queries from "@/graphql/queries";

export default {
  name: "app",

  components: {
    BaseForm,
    BaseField,
  },
  data() {
    return {
      data: {},
      tableConfig: basicConfigFile,
      config: configFile,
    }
  },
  async created() {
    this.getCurrentItemById(this.currentId).then((response)=>{
      const name = `${this.tableConfig['tableName']}`
      this.data = response.data[`get${name}`];
      this.data['createdBy'] = this.kurzel;
      this.$forceUpdate();
    });

  },
  methods: {
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    async getCurrentItemById(id) {
      const name = `${this.tableConfig['tableName']}`
      return await API.graphql({
        query: queries[`get${name}`],
        variables: { id }
      });
    },
    async updateMethod() {
      let saveData = {
        id: this.currentId,
        _version: this.data['_version'],
      };
      this.config.filter((item) => {
        if (item.onUpdate) {
          const name = item["techName"];
          let fieldName =
            item.fieldType === "refDropdown"
              ? `${name}${this.capitalize(this.tableConfig.tableNamePlural)}Id`
              : name;

          if (item.refType === "hasOne") {
            fieldName = `${this.lowerCase(
              this.tableConfig.tableName
            )}${this.capitalize(name)}Id`;
          }
          if (item.refType === "hasOneOld") {
            fieldName = `${name}ID`;
          }
          saveData[fieldName] = this.convertData(this.data[name]);

        }
      });
      saveData = this.reformatDuplicate(saveData);
      await API.graphql({
        query: mutations[`create${this.tableConfig.tableName}`],
        variables: { input: saveData },
      });
      this.$router.push({ name: this.tableConfig.listRoute });
      this.$emit("refresh");
    },
    onConfirmClicked() {
      this.updateMethod();
    },
    reformatDuplicate(formData) {
      delete formData['id'];
      formData['createdBy'] = this.data.createdBy;
      return formData;
    },
    convertData(value) {
      if (value && this.isGoodDate(value)) {
        return this.$moment(value, 'YYYY-MM-DD', "de").format("YYYY-MM-DD");
      }
      if (value && typeof value === 'object' && value.id) {
        return value.id;
      }
      return value;
    },
    isGoodDate(date) {
      const date_regex = /^\d{2}(\.|-)\d{2}(\.|-)\d{4}/;
      return date_regex.test(date);
    },
    lowerCase(s) {
      return s[0].toLowerCase() + s.slice(1);
    },
  },
  computed: {
    ...mapGetters(["user", "kurzel"]),
    currentId() {
      return this.$route.params.id;
    },
  },
};
</script>
